import React from 'react'
import { useSelector } from 'react-redux'
import coverImg from '../../utils/cover-img.jpg'
import './contact.css'
import Loader from '../loader/Loader'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import emailimg from '../../utils/image/email.png';
import callingimg from '../../utils/image/calling12.png';
const Contact = () => {

    const setting = useSelector(state => (state.setting))
    const {t} = useTranslation()
    const placeHolderImage = (e) =>{
        
        e.target.src = setting.setting?.web_logo
    }
    return (
        <section id='contact-us' className='contact-us'>
            {setting.setting === null ? <Loader screen='full' />
                : (
                    <>
                        <div className='cover'>
                            <img  onError={placeHolderImage} src={coverImg} className='img-fluid' alt="cover"></img>
                            <div className='title'>
                                <h3>{t("contact_us")}</h3>
                                <span> <Link to="/" className='text-light text-decoration-none'>{t("home")} /</Link> </span><span className='active'>{t("contact_us")}</span>
                            </div>
                        </div>
                        <div className='container'>
                            <iframe className='mapframe'
                                loading="lazy"
                                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3557.923228440453!2d75.73239517543925!3d26.905931476651276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDU0JzIxLjQiTiA3NcKwNDQnMDUuOSJF!5e0!3m2!1sen!2sin!4v1719405337088!5m2!1sen!2sin"
                                frameborder="0" 
                                allowfullscreen=""
                                title="Location">
                            </iframe>
                            <div className='row operationaladdress'>
                                <div className='contact-wrapper contactus col-lg-4 col-md-4 col-sm-4 '
                                // dangerouslySetInnerHTML={{ __html: setting.setting.contact_us }}
                                >
                                    <i className="fa-solid fa-location-dot contactdetails">
                                        <h3 className='locationcontent'>Location:</h3>
                                    </i>
                                    <h4 className='location-head'>My Ace Courier and Services Pvt Ltd</h4>
                                    <h4 className='location-head'>Operational Address:</h4>
                                    <p className='addresscontent'>
                                        Plot no-B2/21, 2nd floor,<br />
                                        Chitrakoot, opposite Mall of Jaipur,<br />
                                        Vaishali Nagar, Jaipur, Rajasthan 302021
                                    </p>
                                </div>
                                <div className='contact-wrapper contactus col-lg-4 col-md-4 col-sm-4'>
                                    <i className="fa-regular fa-envelope contactdetails">
                                        <h3 className='locationcontent'>Email:</h3>
                                    </i>
                                    <p className='addresscontent'>
                                      <a href={`mailto:${setting.setting !== null ? setting.setting.support_email : "email"}`}>{setting.setting !== null ? setting.setting.support_email : "email"}</a>
                                    </p>
                                </div>
                                <div className='contact-wrapper contactus col-lg-4 col-md-4 col-sm-4'>
                                    <i className="fa-solid fa-mobile contactdetails">
                                        <h3 className='locationcontent'>Call:</h3>
                                    </i>
                                    <p className='location-head'>
                                        Customer Care: 
                                    </p>
                                    <p className='addresscontent'>
                                    <a href={`tel:${setting.setting !== null ? setting.setting.support_number : "number"}`}>{setting.setting !== null ? setting.setting.support_number : "number"}</a>
                                    </p>
                                    <p className='addresscontent'>
                                      6377100162
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                )}


        </section>
    )
}

export default Contact
