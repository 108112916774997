import React, { useState } from 'react';
import './MagnifyImage.css'; // Import the CSS

const MagnifyImage = ({ src }) => {
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [magnifierPos, setMagnifierPos] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - left;
    const y = e.clientY - top;

    if (x < width && y < height && x > 0 && y > 0) {
      setShowMagnifier(true);
      setMagnifierPos({ x, y });
    } else {
      setShowMagnifier(false);
    }
  };

  return (
    <div
      className="image-container image-container3"
      onMouseMove={handleMouseMove}
      onMouseLeave={() => setShowMagnifier(false)}
    >
      <img src={src} alt="Zoomable" className="image" />
      {showMagnifier && (
        <div
          className="magnifier"
          style={{
            top: magnifierPos.y - 50,
            left: magnifierPos.x - 50,
            backgroundImage: `url(${src})`,
            backgroundSize: "200% 200%",
            backgroundPosition: `${-magnifierPos.x * 2 + 10}px ${-magnifierPos.y * 2 + 10}px`,
          }}
        >
          <img src={src} alt='main-product' className='col-12' />
        </div>
      )}
    </div>
  );
};

export default MagnifyImage;
