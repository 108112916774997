import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Pagination from 'react-js-pagination';
import Cookies from 'universal-cookie';
import useByBlogs from '../../hooks/useByBlogs';
import Skeleton from 'react-loading-skeleton';
import './blog.css';

const Blogcontent = () => {
	const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const cookies = new Cookies();

	const appUrl = process.env.REACT_APP_API_URL;
	const appSubUrl = '/storage/';  

    const setting = useSelector(state => state.setting);
    const filter = useSelector(state => state.productFilter);

    const [limit, setLimit] = useState(12);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const { data, totalData, loading } = useByBlogs(cookies.get("jwt_token"), limit, offset,'');

    const placeHolderImage = (e) => {

        e.target.src = setting.setting?.web_logo;
    };


    const handlePageChange = (pageNo) => {
        setCurrentPage(pageNo);
        setOffset(pageNo * limit - limit);
    };

    const placeholderItems = Array.from({ length: 12 }).map((_, index) => index);


    return(
        <>

<section id="blogdetails" className='blogdetailing'>
                <div className='container'>
                    <h2>Blogs & News</h2>

					 {loading ?
                        <div className='row justify-content-center mx-3'>
                            {placeholderItems.map((index) => (
                                <div key={index} className='col-md-3 col-lg-2 col-6 col-sm-3 my-3'>
                                    <Skeleton height={250} />
                                </div>
                            ))}
                        </div>
                        :
                    <div className="wrapper">
					  {data?.map((ctg, index) => (
                        <div className="card blogspost">
                            <div className="card-banner">
                                 <img className="banner-img" src={appUrl + appSubUrl + ctg.image} alt='' />
                            </div>
                            <div className="card-body blogspost-body">
                                <p className="blog-hashtag">{ctg.title} </p>
                                <p className="blog-description">{ctg.short_description} </p>
                                 <Link to={`/blog/${ctg.slug}`} className='btn-lg readbtn'>Read More</Link>
                            </div>
                        </div>
						   ))}

                        
                    </div>
					   }

                    {(limit < totalData) &&
                        <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={limit}
                            totalItemsCount={totalData}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange.bind(this)}
                        />
                    }
                </div>
            </section>

        </>
    )
}
export default Blogcontent;