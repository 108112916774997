import React from 'react';
import './categoryfeecalculation.css';
const Categoryfeecal = () => {
  return (
<>


<section id="categoryFeeCalculation" className='categoryFeeCalculation'>
        <div className="container categoryfee">
            <div className="row">
                <div className='category mb-4'>
                <h1 className="mt-3">Category Fee Calculation</h1>
                <h2 className="content-heading mt-4">Product Category Fee Structure</h2>
                <p className="content-text mt-3">When selling products through our platform, it's important to understand that each product category incurs a specific fee. This fee is a percentage of the total selling price of the product and helps support the infrastructure and services we provide.</p>
                <h2 className="content-heading mt-4">How the Fee Works:</h2>
                <ol className="pl-3 listing">
                    <li className="mt-3">
                        <h3 className="list-item-heading">Category-Specific Rates: <span className="list-item-text">Each product falls into a category that has its own fee rate. These rates vary depending on the category to account for different levels of service and market conditions.</span></h3>
                    </li>
                    <li className="mt-3">
                        <h3 className="list-item-heading">Percentage Calculation: <span className="list-item-text">The category fee is calculated as a percentage of the total selling price of your product. This percentage is deducted from the amount you earn from each sale.</span></h3>
                    </li>
                    <li className="mt-3">
                        <h3 className="list-item-heading">Fee Transparency: <span className="list-item-text">The applicable fee percentage for each category is clearly outlined in our fee schedule. You can review this schedule to understand the costs associated with each category before listing your products.</span></h3>
                    </li>
                    <li className="mt-3">
                        <h3 className="list-item-heading">Example Calculation:</h3>
                        <p className="example-calculation">Total Category Fees = Item Price x Category Fee Percentage</p>
                        <p className="example-calculation">If you are selling a book priced at ₹ 450 and if the referral fee percentage for books category is 4%, the Category Fees = ₹ 450 x 4% = ₹ 18. This amount will be deducted from your total earnings from that sale.</p>
                    </li>
                    <li className="mt-3">
                        <h3 className="list-item-heading">Fee Deduction: <span className="list-item-text">The fee is automatically deducted from your sales proceeds, so you receive your payment minus the applicable category fee.</span></h3>
                    </li>
                </ol>
                </div>
                
         <div className='table-responsive'>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Parent Category</th>
                  <th scope="col">Child Category</th>
                  <th scope="col">Charges in %</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan="5">1. Baby Products, Toys &amp; Education</td>
                  <td>
                    Baby Hardlines - Swings, Bouncers and Rockers, Carriers, Walkers Baby Safety - Guards &amp; Locks Baby Room Décor Baby Furniture Baby Car Seats &amp; Accessories
                  </td>
                  <td>
                    5.5% for item price &lt;= INR 300<br/>
                    7.5% for item price &gt; INR 300 and &lt;= INR 500<br />
                    6% for item price &gt; INR 500 and &lt;= INR 1000<br />
                    7.0% for item price &gt; INR 1000
                  </td>
                </tr>
                <tr>
                  <td>Baby Strollers</td>
                  <td>
                    4% for item price &lt;= INR 300<br />
                    5.5% for item price &gt; INR 300 and &lt;= INR 1000<br />
                    9% for item price &gt; INR 1000
                  </td>
                </tr>
                <tr>
                  <td>Baby diapers</td>
                  <td>
                    5.0% for item price &lt;= INR 300<br />
                    5.5% for item price &gt; INR 300 and &lt;= INR 500<br />
                    9.0% for item price &gt; INR 500
                  </td>
                </tr>
                <tr>
                  <td>Toys - Drones</td>
                  <td>
                    7.0% for item price &lt;= INR 300<br />
                    15% for item price &gt; INR 300 and &lt;= INR 500<br />
                    12.5% for item price &gt; INR 500 and &lt;= INR 1000<br />
                    11.5% for item price &gt; INR 1000
                  </td>
                </tr>
                <tr>
                  <td>Toys - Balloons and Soft Toys</td>
                  <td>11.00%</td>
                </tr>
                <tr>
                  <td rowspan="10">2. Books, Music, Movies, Video Games, Entertainment</td>
                  <td>Books</td>
                  <td>
                    3% for item price &lt;= INR 250<br />
                    4.0% for item price &gt; INR 250 and &lt;= INR 500<br />
                    8.5% for item price &gt; INR 500 and &lt;= INR 1000<br/>
                    13.0% for item price &gt; INR 1000
                  </td>
                </tr>
                <tr>
                  <td>Music</td>
                  <td>6.50%</td>
                </tr>
                <tr>
                  <td>Musical Instruments – Guitars</td>
                  <td>10%</td>
                </tr>
                <tr>
                  <td>Musical Instruments – Keyboards</td>
                  <td>
                  8.0% for item price &lt;= 500<br/>
                  10.0% for item price &gt; 500
                  </td>
                </tr>
                <tr>
                  <td>Musical Instruments – Microphones</td>
                  <td>9.50%</td>
                </tr>
                <tr>
                  <td>Musical Instruments – Others</td>
                  <td>
                    8.0% for item price &lt;= 300<br/>
                    7.0% for item price &gt; 300 and &lt;= 500<br/>
                    8.5% for item price &gt; 500 and &lt;= 1,000<br/>
                    9.0% for item price &gt; 1,000
                  </td>
                </tr>
                <tr>
                  <td>Musical Instruments - DJ & VJ Equipment, Recording and Computer, Cables & Leads, PA & Stage</td>
                  <td>
                   6.0% for item price &lt;= 300<br/>
                   4.5% for item price &gt; 300 and &lt;= 500<br/>
                   5.5% for item price &gt; 500 and &lt;= 1,000<br/>
                   9.5% for item price &gt; 1,000
                  </td>
                </tr>
                <tr>
                  <td>Video Games – Accessories</td>
                  <td>
                    9.0% for item price &lt;= 500<br/>
                    8.0% for item price &gt; 500 and &lt;= 1,000<br/>
                    10.0% for item price &gt; 1,000

                  </td>
                </tr>
                <tr>
                  <td>Video Games – Consoles</td>
                  <td>
                    9.0% for item price &lt;= 500<br/>
                    8.0% for item price &gt; 500 and &lt;= 1,000<br/>
                    10.0% for item price &gt; 1,000

                  </td>
                </tr>
                <tr>
                  <td>Video Games</td>
                  <td>
                    2.0% for item price &lt;= 500<br/>
                    3.5% for item price &gt; 500
                  </td>
                </tr>
                <tr>
                  <td rowSpan="16">3. Industrial, Medical, Scientific Supplies & Office Products</td>
                  <td>Business and Industrial Supplies - Scientific Supplies</td>
                  <td>
                    11% for item price &lt;= INR 300<br />
                    11.5% for item price &gt; INR 300 and &lt;= INR 15000<br />
                    7% for item price &gt; INR 15000
                  </td>
                </tr>
                <tr>
                  <td>OTC Medicine</td>
                  <td>
                    12.0% for item price &lt;= 500<br />
                    15.0% for item price &gt; 500
                  </td>
                </tr>
                <tr>
                  <td>Masks</td>
                  <td>7%</td>
                </tr>
                <tr>
                  <td>Weighing Scales & Fat Analyzers</td>
                  <td>
                    15.0% for item price &lt;= 500<br />
                    10.0% for item price &gt; 500
                  </td>
                </tr>
                <tr>
                  <td>3D Printers</td>
                  <td>11%</td>
                </tr>
                <tr>
                  <td>Business and Industrial Supplies - Material Handling Equipment, Janitorial & Sanitation, Medical & Dental Supplies, Commercial kitchen and refrigeration Equipment</td>
                  <td>5.50%</td>
                </tr>
                <tr>
                  <td>Business and Industrial Supplies - Electrical Testing, Dimensional Measurement, Thermal Printers, Barcode Scanners</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td>Business and Industrial Supplies - Power tools & accessories, Welding machines, Microscopes, Industrial Electrical products</td>
                  <td>10%</td>
                </tr>
                <tr>
                  <td>Occupational Safety Supplies (Mask, gloves, Safety shoes, Face shields & other PPE products)</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td>Stethoscopes</td>
                  <td>9.50%</td>
                </tr>
                <tr>
                  <td>Packing materials</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td>Power & hand Tools and Water Dispenser</td>
                  <td>
                    8.0% for item price &lt;= 500<br/>
                    9.0% for item price &gt; 500
                  </td>
                </tr>
                <tr>
                  <td>Office products - Office supplies</td>
                  <td>
                     8.0% for item price &lt;=500<br/>
                     10.0% for item price &gt; 500
                  </td>
                </tr>
                <tr>
                  <td>Office products - Electronic Devices</td>
                  <td>
                    7.5% for item price &lt;=300<br/>
                    4.5% for item price &gt; 300 and &lt;= 500<br/>
                    10.0% for item price &gt; 500 and &lt;= 1,000<br/>
                    10.5% for item price &gt;1,000
                  </td>
                </tr>
                <tr>
                  <td>Office products - Arts and Crafts</td>
                  <td>
                    10% for item price &lt;= INR 500<br />
                    11% for item price &gt; INR 500 and &lt;= INR 1000 <br />
                    12.5% for item price &gt; INR 1000
                  </td>
                </tr>
                <tr>
                  <td>Office products - Writing Instruments</td>
                  <td>
                    10% for item price &lt;= INR 300<br />
                    11% for item price &gt; INR 300 and &lt;= INR 1000 <br />
                    12.5% for item price &gt; INR 1000
                  </td>
                </tr>
                <tr>
                    <td rowSpan="23">4. Clothing, Fashion, Fashion Accessories,
                        Jewellery, Luggage, Shoes
                        </td>
                    <td>Apparel – Accessories</td>
                    <td>
                        13% for item price &lt;= INR 500<br/>
                        18% for item price &gt; INR 500                        
                    </td>
                  </tr>
                  <tr>
                    <td>Apparel - Sweat Shirts and Jackets</td>
                    <td>
                      8.0% for item price &lt;= 500<br/>
                      9.0% for item price &gt;500
                    </td>
                  </tr>
                  <tr>
                    <td>Apparel – Shorts</td>
                    <td>
                        16.0% for item price &lt;= INR 300<br/>
                        13.0% for item price &gt; INR 300 and &lt;= INR 500<br/>
                        22.5% for item price &gt; INR 500 and &lt;= INR 1000<br/>
                         23% for item price &gt; INR 1000                                               
                    </td>
                  </tr>
                  <tr>
                    <td>Apparel – Baby</td>
                    <td>
                      6.0% for item price &lt;= INR 300<br/>
                      7% for item price &gt; INR 300 and &lt;= INR 500<br/> 
                      12.0% for item price &gt; INR 500 and &lt;= INR 1000<br/>     
                      16.0% for item price  &gt; INR 1000                                                                
                    </td>
                  </tr>
                  <tr>
                    <td>Apparel - Ethnic wear</td>
                    <td>
                        6% for item price &lt;= INR 300<br/>
                        4% for item price &gt; INR 300 and &lt;= INR 500<br/>
                        7% for item price &gt; INR 500 and &lt;= INR 1000<br/>
                        14.0% for item price &gt; INR 1000                                                                                            
                    </td>
                  </tr>
                  <tr>
                    <td>Apparel - Other innerwear</td>
                    <td>
                       10.0% for item price &lt;= 300<br/>
                       9.0% for item price &gt; 300 and &lt;= 500<br/>
                       13.5% for item price &gt;500 and &lt;= 1,000<br/>
                       15.5% for item price &gt;1,000                                                                                                              
                    </td>
                  </tr>
                  <tr>
                    <td>Apparel – Sleepwear</td>
                    <td>
                      10.0% for item price &lt;=500<br/>
                      14.0% for item price &gt; 500 and &lt;=1,000<br/>
                      16.0% for item price &gt;1,000                                                                                                                                       
                    </td>
                  </tr>
                  <tr>
                    <td>Apparel - Sarees and Dress Materials</td>
                    <td>
                        7.0% for item price &lt;= INR 300<br/>
                        8.0% for item price &gt; INR 300 and &lt;= INR 500<br/> 
                        9.0% for item price &gt; INR 500 and &lt;= INR 1000<br/> 
                        17.0% for item price &gt; INR 1000                                                                                                                                                               
                    </td>
                  </tr>
                  <tr>
                    <td>Apparel - Men's T-shirts (except Polos, Tank tops and full
                        sleeve tops)
                        </td>
                    <td>
                        2.0% for item price &lt;= INR 300<br/>
                        5.0% for item price &gt; 300 and &lt;= 500<br/>
                        17.0% for item price &gt; INR 500                                                                                                                                                                                     
                    </td>
                  </tr>
                  <tr>
                    <td>Apparel - Womens' Innerwear / Lingerie</td>
                    <td>
                       10.0% for item price &lt;= 500<br/>
                       14.0% for item price &gt;500 and &lt;=1,000<br/>
                       12% for item price &gt; 1,000                                                                                                                                                                                                         
                    </td>
                  </tr>
                  <tr>
                    <td>Backpacks</td>
                    <td>
                        9.0% for item price &lt;= 300<br/>
                        8.0% for item price &gt; 300 and &lt;=500<br/>
                        9.5% for item price &gt; 500 and &lt;=1,000<br/>
                        11.0% for item price &gt; 1,000                                                                                                                                                                                                                              
                    </td>
                  </tr>
                  <tr>
                    <td>Eyewear - Sunglasses, Frames and zero power eye glasses</td>
                    <td>
                       11.0% for item price &lt;= 500<br/>
                       11.5% for item price &gt; 500 and &lt;=1,000<br/>
                       15.0% for item price &gt;1,000                                                                                                                                                                                                                                                     
                    </td>
                  </tr>
                  <tr>
                    <td>Fashion Jewellery</td>
                    <td>
                      9% for item price &lt;= 500<br/>
                      11.5% for item price &gt; 500                                                                                                                                                                                                                                                     
                    </td>
                  </tr>
                  <tr>
                    <td>Silver Jewellery</td>
                    <td>
                      10.5% for item price &lt;= 1,000<br/>
                      11.0% for item price &gt; 1,000                                                                                                                                                                                                                                                                             
                    </td>
                  </tr>
                  <tr>
                    <td>Flip Flops, Fashion Sandals and Slippers</td>
                    <td>
                      11.0% for item price &lt;= 500<br/>
                      10% for item price &gt;500 and &lt;=1,000<br/>
                      13% for item price &gt; 1,000                                                                                                                                                                                                                                                                           
                    </td>
                  </tr>
                  <tr>
                    <td>Handbags</td>
                    <td>
                       3.0% for item price &lt;= 300<br/>
                       8.0% for item price &gt;300 and &lt;=500<br/>
                       9.0% for item price &gt;500 and &lt;=1,000<br/>
                       10.0% for item price &gt; 1,000                                                                                                                                                                                                                                                                                                    
                    </td>
                  </tr>
                  <tr>
                    <td>Luggage - Suitcase & Trolleys</td>
                    <td>
                        7.5% for item price &lt;= INR 500<br/>
                        6.5% for item price &gt; INR 500 and &lt;= INR 1000<br/> 
                        5.5% for item price &gt; INR 1000                                                                                                                                                                                                                                                                                                     
                    </td>
                  </tr>
                  <tr>
                    <td>Luggage - Travel Accessories</td>
                    <td>
                       9.0% for item price &lt;= 300<br/>
                       8.0% for item price &gt; 300 and &lt;= 500<br/>
                       9.0% for item price &gt; 500 and &lt;= 1,000<br/>
                       10.0% for item price &gt; 1,000                                                                                                                                                                                                                                                                                                                           
                    </td>
                  </tr>
                  <tr>
                    <td>Kids shoes</td>
                    <td>
                        10.0% for item price &lt;= INR 300<br/>
                        13.0% for item price &gt; INR 300 and &lt;= INR 1000<br/> 
                        15.5% for item price &gt; INR 1000                                                                                                                                                                                                                                                                                                                            
                    </td>
                  </tr>
                  <tr>
                    <td>Shoes</td>
                    <td>
                      13.0% for item price &lt;=300<br/>
                      14.5% for item price &gt; 300 and &lt;= 1,000<br/>
                      14.5% for item price &gt;1,000                                                                                                                                                                                                                                                                                                                                              
                    </td>
                  </tr>
                  <tr>
                    <td>Shoes - Sandals & Floaters</td>
                    <td>
                        10.50%                                                                                                                                                                                                                                                                                                                                                
                    </td>
                  </tr>
                  <tr>
                    <td>Wallets</td>
                    <td>
                       9.0% for item price &lt;= 500<br/>
                       10.5% for item price &gt; 500 and &lt;=1,000<br/>
                       11.0% for item price &gt;1,000                                                                                                                                                                                                                                                                                                                                                                    
                    </td>
                  </tr>
                  <tr>
                    <td>Watches</td>
                    <td>14%                                                                                                                                                                                                                                                                                                                                                                    
                    </td>
                  </tr>
                <tr>
                  <td rowSpan="33">5. Electronics (Camera, Mobile, PC, Wireless) & Accessories</td>
                  <td>Cables and Adapters - Electronics, PC and Wireless</td>
                  <td>
                    21.0% for item price &lt;= INR 300<br />
                    16.5% for item price &gt; INR 300 and &lt;= INR 500<br />
                    19.0% for item price &gt; INR 500
                  </td>
                </tr>
                <tr>
                  <td>Camera Accessories</td>
                  <td>
                     11.0% for item price &lt;= 500<br/>
                     12.0% for item price &gt;500 and &lt;= 1,000<br/>
                     13.0% for item price &gt;1,000
                  </td>
                </tr>
                <tr>
                  <td>Camera Lenses</td>
                  <td>7%</td>
                </tr>
                <tr>
                  <td>Camera and Camcorder</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td>Cases, Covers, Skins, Screen Guards</td>
                  <td>
                    3.0% for item price &lt;=  150<br/>
                    12.0% for item price &gt; 150 and &lt;=  300<br/>
                    19.0% for item price &gt; 300 and &lt;=  500<br/>
                    21.0% for item price &gt; 500
                  </td>
                </tr>
                <tr>
                  <td>Desktops</td>
                  <td>6%</td>
                </tr>
                <tr>
                  <td>Electronic Accessories (Electronics, PC & Wireless)</td>
                  <td>16%</td>
                </tr>
                <tr>
                  <td>Electronic Devices (except TV, Camera & Camcorder, Camera Lenses and Accessories, GPS Devices, Speakers)</td>
                  <td>9%</td>
                </tr>
                <tr>
                  <td>Entertainment Collectibles</td>
                  <td>
                    12.5% for item price &lt;= 300<br />
                    16% for item price &gt; 300
                  </td>
                </tr>
                <tr>
                  <td>Fashion Smartwatches</td>
                  <td>14.50%</td>
                </tr>
                <tr>
                  <td>GPS Devices</td>
                  <td>13.50%</td>
                </tr>
                <tr>
                  <td>Hard Disks</td>
                  <td>
                    7.5% for item price &lt;= 1,000<br/>
                    9.5% for item price &gt; 1,000
                  </td>
                </tr>
                <tr>
                    <td>Headsets, Headphones and Earphones</td>
                    <td>
                      16.0% for item price &lt;= 500<br/>
                      15.5% for item price &gt;500 and &lt;= 1,000<br/>
                      17.0% for item price &gt; 1,000
                    </td>
                </tr>
                <tr>
                    <td>Keyboards and Mouse</td>
                    <td>
                      12.0% for item price &lt;=500<br/>
                      13.5% for item price &gt;500 and &lt;= 1,000<br/>
                      16% for item price &gt; 1,000
                    </td>
                </tr>
                <tr>
                    <td>Kindle Accessories</td>
                    <td>
                        20%
                    </td>
                </tr>
                <tr>
                  <td>Laptop Bags & Sleeves</td>
                  <td>
                    9.0% for item price &lt;= 300<br/>
                    10.0% for item price &gt; 300 and &lt;= 500<br/>
                    11.0% for item price &gt;500 and &lt;= 1,000<br/>
                    12.5% for item price &gt; 1,000
                  </td>
                </tr>
                <tr>
                  <td>Laptop and Camera Battery</td>
                  <td>
                    12.0% for item price &lt;= 300<br/>
                    10.5% for item price &gt;300 and &lt;=500<br/>
                    12.0% for item price &gt;500 and &lt;= 1,000<br/>
                    12.5% for item price &gt; 1,000
                  </td>
                </tr>
                <tr>
                  <td>Laptops</td>
                  <td>
                    6.0% for item price ≤ 70,000<br />
                    7.0% for item price &gt; 70,000
                  </td>
                </tr>
                <tr>
                  <td>Memory Cards</td>
                  <td>13%</td>
                </tr>
                <tr>
                  <td>Mobile phones</td>
                  <td>5.50%</td>
                </tr>
                <tr>
                  <td>Tablets (including Graphic Tablets)</td>
                  <td>
                    6% for item price ≤ INR 12000<br />
                    9% for item price &gt; INR 12000
                  </td>
                </tr>
                <tr>
                  <td>Modems & Networking Devices</td>
                  <td>12%</td>
                </tr>
                <tr>
                  <td>Monitors</td>
                  <td>
                    6.5% for item price &lt;= 1,000<br/>
                    8.0% for item price &gt; 1,000
                  </td>
                </tr>
                <tr>
                  <td>PC Components (RAM,<br />Motherboards)</td>
                  <td>5.50%</td>
                </tr>
                <tr>
                  <td>Power Banks & Chargers</td>
                  <td>
                    19% for item price ≤ INR 1000<br />
                    19.5% for item price &gt; INR 1000
                  </td>
                </tr>
                <tr>
                  <td>Printers & Scanners</td>
                  <td>
                    7.0% for item price &lt;= 500<br/>
                    8.5% for item price &gt; 500 and &lt;= 1,000<br/>
                    10.0% for item price &gt; 1,000
                  </td>
                </tr>
                <tr>
                  <td>Software Products</td>
                  <td>9.50%</td>
                </tr>
                <tr>
                  <td>Speakers</td>
                  <td>
                    8.0% for item price &lt;= 500<br/>
                    8.5% for item price &gt; 500 and &lt;= 1,000<br/> 
                    10.0% for item price &gt; 1,000
                  </td>
                </tr>
                <tr>
                  <td>Television and Interactive Flat Panel</td>
                  <td>6%</td>
                </tr>
                <tr>
                  <td>Landline Phones</td>
                  <td>7%</td>
                </tr>
                <tr>
                  <td>Smart Watches & Accessories</td>
                  <td>
                    13.5% for item price &lt;= 500<br/> 
                    14.5% for item price &gt; 500 and &lt;= 1,000<br/>
                    15.0% for item price &gt; 1,000
                  </td>
                </tr>
                <tr>
                  <td>USB Flash Drives (Pen Drives)</td>
                  <td>14%</td>
                </tr>
                <tr>
                  <td>Projectors, Home Theatre Systems,<br />Binoculars and Telescopes</td>
                  <td>6%</td>
                </tr>
                <tr>
                  <td rowSpan="6">6. Grocery, Food and Pet Supplies</td>
                  <td>Grocery - herbs and spices</td>
                  <td>
                    4% for item price ≤ INR 300<br />
                    5.5% for item price &gt; INR 300 and ≤ INR 1000<br />
                    8.0% for item price &gt; INR 1000
                  </td>
                </tr>
                <tr>
                  <td>Grocery & Gourmet – Oils</td>
                  <td>
                    7.5% for item price ≤ INR 1000<br />
                    9% for item price &gt; INR 1000
                  </td>
                </tr>
                <tr>
                  <td>Grocery - Dried fruits and nuts</td>
                  <td>
                    6.0% for item price &lt;= 500.<br/>
                    7.0% for item price &gt;500 and &lt;=1,000<br/>
                    8.0% for item price &gt; 1,000
                  </td>
                </tr>
                <tr>
                  <td>Grocery - Hampers and gifting</td>
                  <td>
                    9% for item price ≤ INR 1000<br />
                    11.5% for item price &gt; INR 1000
                  </td>
                </tr>
                <tr>
                  <td>Pet food</td>
                  <td>
                    5.5% for item price ≤ INR 300<br />
                    11.5% for item price &gt; INR 300 and ≤ INR 1000<br />
                    12.5% for item price &gt; INR 1000
                  </td>
                </tr>
                <tr>
                    <td>Pet Products</td>
                    <td>
                      2.0% for item price &lt;= 300<br/>
                      8.0% for item price &gt; 300 and &lt;= 500<br/>
                      9.0% for item price &gt; 500                  
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan="22">7. Health, Beauty, Personal Care & Personal Care Appliances</td>
                    <td>Beauty – Fragrance</td>
                    <td>
                        13.5% for item price &lt;= INR 300<br />
                        12.5% for item price &gt; INR 300 and &lt;= INR 500 <br />
                        13% for item price &gt; INR 500
                    </td>
                  </tr>
                  <tr>
                    <td>
                        Beauty - Haircare, Bath and Shower</td>
                    <td>
                        7.5% for item price &lt;= INR 300<br />
                        5.0% for item price &gt; INR 300 and &lt;= INR 500 <br />
                        8.0% for item price &gt; INR 500
                    </td>
                  </tr>
                  <tr>
                    <td>
                        Beauty – Makeup</td>
                    <td>
                        6% for item price &lt;= INR 300<br />
                        4.5% for item price &gt; INR 300 and &lt;= INR 500 <br />
                        7.5% for item price &gt; INR 500 and &lt;= INR 1000 <br/>
                        7% for item price &gt; INR 1000
                    </td>
                  </tr>
                <tr>
                  <td>Face Wash</td>
                  <td>
                    5% for item price &gt; INR 300 and &lt;= INR 500<br />
                    9% for item price &gt; INR 500 and &lt;= INR 1000<br />
                    9.5% for item price &gt; INR 1000
                  </td>
                </tr>
                <tr>
                  <td>Moisturizer cream</td>
                  <td>
                    6.5% for item price &lt;= INR 500<br />
                    9% for item price &gt; INR 500 and &lt;= INR 1000<br />
                    9.5% for item price &gt; INR 1000
                  </td>
                </tr>
                <tr>
                  <td>Sunscreen</td>
                  <td>
                    5.5% for item price  &lt;=300<br/>
                    6.0% for item price &gt;300 and  &lt;=500<br/>
                    7.0% for item price &gt;500 and  &lt;=1,000<br/>
                    7.5% for item price &gt;1,000
                  </td>
                </tr>
                <tr>
                  <td>Deodrants</td>
                  <td>
                    8.5% for item price &lt;= INR 300<br />
                    6.5% for item price &gt; INR 300 and &lt;= INR 1000<br />
                    7% for item price &gt; INR 1000
                  </td>
                </tr>
                <tr>
                  <td>Facial steamers</td>
                  <td>
                    6.0% for item price &lt;=300 <br/>
                    5.5% for item price &gt;300 and &lt;=500<br/>
                    6.0% for item price &gt;500
                  </td>
                </tr>
                <tr>
                  <td>Prescription Medicine</td>
                  <td>6%</td>
                </tr>
                <tr>
                  <td>Health & Personal Care (HPC) - Medical Equipment & Contact Lens</td>
                  <td>8%</td>
                </tr>
                <tr>
                  <td>Health and Personal Care - Ayurvedic products, Oral care, hand sanitizers, Pooja supplies</td>
                  <td>
                    6.0% for item price &lt;= 500<br/>
                    7.0% for item price &gt;500 and &lt;=1,000<br/>
                    8.0% for item price &gt; 1,000
                  </td>
                </tr>
                <tr>
                  <td>Health & Household - Sports Nutrition and meal replacement shakes</td>
                  <td>8%</td>
                </tr>
                <tr>
                  <td>Health and Personal Care - Contact lens and reading Glasses</td>
                  <td>12%</td>
                </tr>
                <tr>
                  <td>Health and Household - Household Cleaning, Laundry, Air Fresheners, Personal Hygiene (Handwash, Feminine Hygiene, Oral Care)</td>
                  <td>
                    4.5% for item price &lt;= INR 500<br />
                    7.5% for item price &gt; INR 500 and &lt;= INR 1000<br />
                    8% for item price &gt; INR 1000
                  </td>
                </tr>
                <tr>
                  <td>Health and Household - Vitamins & Mineral Health Supplements</td>
                  <td>
                    9.0% for item price &lt;= INR 500<br />
                    10.5% for item price &gt; INR 500
                  </td>
                </tr>
                <tr>
                  <td>Luxury Beauty</td>
                  <td>
                    5% for item price &lt;= INR 300<br />
                    6% for item price &gt; INR 300 and &lt;= INR 500<br />
                    9% for item price &gt; INR 500 and &lt;= INR 1000<br />
                    10% for item price &gt; INR 1000
                  </td>
                </tr>
                <tr>
                  <td>Car Cradles, Lens Kits and Tablet Cases</td>
                  <td>
                    16.0% for item price &lt;= 500<br/>
                    20.0% for item price &gt; 500 and &lt;=1,000<br/>
                     25.0% for item price &gt;1,000
                  </td>
                </tr>
                <tr>
                  <td>Personal Care Appliances - Electric Massagers</td>
                  <td>
                    9.5% for item price &lt;= 500<br/>
                    11.5% for item price &gt;500 and &lt;= 1,000<br/>
                    12.5% for item price &gt; 1,000
                  </td>
                </tr>
                <tr>
                  <td>Personal Care Appliances (Grooming & Styling)</td>
                  <td>
                    9.0% for item price &lt;= 500<br />
                    8.0% for item price &gt;500 and &lt;= 1,000<br />
                    8.0% for item price &gt; 1,000
                  </td>
                </tr>
                <tr>
                  <td>Personal Care Appliances - Glucometer and Glucometer Strips</td>
                  <td>5.50%</td>
                </tr>
                <tr>
                  <td>Personal Care Appliances-Thermometers</td>
                  <td>12.5% for item price &lt;= 500<br/>
                  10.5% for item price &gt;500
                  </td>
                </tr>
                    <tr>
                        <td>
                            Personal Care Appliances -<br/>
                            Weighing Scales and FatAnalyzers
                        </td>
                        <td>
                           9% for item price &lt;= 300<br/>
                           8.5% for &gt;300 and &lt;=500<br/>
                           10.5% for item price &gt; 500
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="43" className="align-top">
                            8. Home, Décor, Home Improvement,<br/>
                            Furniture, Outdoor, Lawn &amp; Garden
                        </td>
                        <td>Bean Bags &amp; Inflatables</td>
                        <td>11%</td>
                    </tr>
                    <tr>
                        <td>Mattresses</td>
                        <td>
                            25.5% for item price &lt;= INR 1000<br/>
                            16% for item price &gt; INR 1000 and &lt;= INR 20000<br/>
                            13.5% for item price &gt; INR 20000
                        </td>
                    </tr>
                    <tr>
                        <td>Rugs and Doormats</td>
                        <td>
                          4.0% for item price &lt;=300<br/>
                          9.0% for item price &gt;300
                        </td>
                    </tr>
                    <tr>
                        <td>Clocks</td>
                        <td>
                          4.0% for item price &lt;=300 <br/>
                          9.0% for item price &gt;300 and &lt;=1,000<br/>
                          12.0% for item price &gt;1,000
                        </td>
                    </tr>
                    <tr>
                        <td>Wall Art</td>
                        <td>
                          5.0% for item price &lt;= 300<br/>
                          10.0% for item price &gt; 300 and &lt;= 500<br/>
                          11.5% for item price &gt; 500
                        </td>
                    </tr>
                    <tr>
                        <td>Home - Fragrance &amp; Candles</td>
                        <td>
                          3.0% for item price &lt;=300<br/>
                          7.0% for item price &gt;300 and &lt;= 500<br/>
                          8% for item price &gt; 500
                        </td>
                    </tr>
                    <tr>
                        <td>Bedsheets, Blankets and covers</td>
                        <td>
                            9.0% for item price &lt;= 300 <br/>
                            8.0% for item price &gt; 300 and &lt;=500<br/>
                            9.5% for item price  &gt; 500 and &lt;=1,000<br/>
                            12.0% for item price  &gt; 1,000
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Home furnishing (Excluding curtain and curtain<br/>
                            accessories)
                        </td>
                        <td>
                          3.0% for item price &lt;= 300<br/>
                          6.0% for item price &gt;300 and &lt;= 1,000<br/>
                          7.0% for item price &gt; 1,000
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Containers, Boxes, Bottles, and Kitchen Storage
                        </td>
                        <td>
                          2.0% for item price &lt;= 300<br/>
                          5.0% for item price &gt; 300 and &lt;= 500<br/>
                          10.0% for item price &gt; 500
                        </td>
                    </tr>
                    <tr>
                        <td>Home improvement – Accessories</td>
                        <td>
                          8.0% for item price &lt;=500<br/>
                          7.0% for item price &gt;500 and &lt;=1,000<br/>
                          6.5% for item price &gt;1,000
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Home improvement (excl. accessories), including Home<br/>
                            Security Systems
                        </td>
                        <td>9%</td>
                    </tr>
                    <tr>
                        <td>Tiles &amp; Flooring Accessories</td>
                        <td>8%</td>
                    </tr>
                    <tr>
                        <td>
                            Wires (Electrical Wires/cables for house wiring, adhoc<br/>
                            usage)
                        </td>
                        <td>10%</td>
                    </tr>
                    <tr>
                        <td>Ladders, Kitchen and Bath fixtures</td>
                        <td>8%</td>
                    </tr>
                    <tr>
                        <td>
                            Home Storage (Excluding Kitchen Containers, Boxes,Bottles, and<br/>
                            Kitchen Storage)
                        </td>
                        <td>
                          5.5% for item price &lt;= 300<br/>
                          9.0% for item price &gt; 300 and &lt;= 1,000<br/>
                          12.0% for item price &gt;1,000
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Wallpapers &amp; Wallpaper<br/>
                            Accessories
                        </td>
                        <td>
                            13% for item price &lt;= INR 1000<br/>
                            9% for item price &gt; INR 1000
                        </td>
                    </tr>
                    <tr>
                        <td>Home Decor Products</td>
                        <td>
                          6.0% for item price &lt;= 300<br/>
                          12.0% for item price &gt;300 and &lt;= 1,000<br/>
                          17.0% for item price &gt; 1,000
                        </td>
                    </tr>
                    <tr>
                        <td>Wall Paints and Tools</td>
                        <td>
                            9% for item price &lt;= INR 300<br/>
                            6% for item price &gt; INR 300
                        </td>
                    </tr>
                    <tr>
                        <td>Home - Waste &amp; Recycling</td>
                        <td>
                          7.5% for item price &lt;= 300<br/>
                          8.0% for item price &gt; 300
                        </td>
                    </tr>
                    <tr>
                        <td>Water Purifier &amp; Accessories</td>
                        <td>
                          6.5% for item price &lt;= 5000<br/>
                          7.5% for item price &gt; 5000
                        </td>
                    </tr>
                    <tr>
                        <td>Craft materials</td>
                        <td>
                          2% for item price &lt;= INR 500<br/>
                          5% for item price &gt; INR 500
                        </td>
                    </tr>
                    <tr>
                        <td>Water Heaters &amp; Accessories</td>
                        <td>
                          8% for item price &lt;= INR 5000<br/>
                          9% for item price &gt; INR 5000
                        </td>
                    </tr>
                    <tr>
                        <td>Home improvement - Kitchen &amp; Bath (Fittings, accessories), Cleaning Supplies, Electricals, Hardware, Building Materials</td>
                        <td>
                          5.0% for item price &lt;= 300<br/>
                          9.0% for item price &gt;300 and &lt;= 500<br/>
                          10.0% for item price &gt; 500
                        </td>
                    </tr>
                    <tr>
                        <td>Sanitaryware - Toilets, Bathtubs, Basins/Sinks, Bath Mirrors &amp; Vanities, and Shower Enclosures/partitions</td>
                        <td>
                        10%
                        </td>
                    </tr>
                    <tr>
                        <td>Home Safety &amp; Security Systems</td>
                        <td>
                          6%
                        </td>
                    </tr>
                    <tr>
                        <td>Inverter and Batteries</td>
                        <td>
                          4.50%
                        </td>
                    </tr>
                    <tr>
                        <td>Cleaning and Home Appliances</td>
                        <td>
                          7.5% for item price &lt;= INR 5000<br/>
                          8.5% for item price &gt; INR 5000
                        </td>
                    </tr>
                    <tr>
                        <td>Ladders</td>
                        <td>
                          9%
                        </td>
                    </tr>
                    <tr>
                        <td>Indoor Lighting – Wall, ceiling fixture lights, lamp bases, lamp shades and Smart Lighting</td>
                        <td>
                          6.0% for item price &lt;= 500<br/>
                          12.5% for item price &gt; 500 and &lt;= 1,000<br/>
                          9.5% for item price &gt; 1,000
                        </td>
                    </tr>
                    <tr>
                        <td>Doors and Windows (wooden, metal, PVC/UPVC Doors &amp; Windows)</td>
                        <td>
                        10%
                        </td>
                    </tr>
                    <tr>
                        <td>LED Bulbs and Battens</td>
                        <td>
                          2.0% for item price &lt;= 300 <br/>
                          6.5% for item price &gt; 300 and &lt;= 500<br/>
                          12.0% for item price &gt; 500
                        </td>
                    </tr>
                    <tr>
                        <td>Cushion Covers</td>
                        <td>
                          3.0% for item price &lt;= 300<br/>
                          7.0% for item price &gt; 300 and &lt;=500<br/>
                          8.0% for item price &gt; 500
                        </td>
                    </tr>
                    <tr>
                        <td>Curtains and Curtain Accessories</td>
                        <td>
                          13.5% for item price &lt;= 300<br/>
                          14.5% for item price &gt; 300 and &lt;= 500<br/>
                          9.5% for item price &gt; 500 and &lt;= 1,000<br/>
                          12.5% for item price &gt; 1,000
                        </td>
                    </tr>
                    <tr>
                        <td>Slipcovers and Kitchen Linens</td>
                        <td>
                          7.5% for item price &lt;= 300<br/>
                          9.5% for item price &gt; 300 and &lt;= 500<br/>
                          13.50% for item price &gt; 500
                        </td>
                    </tr>
                    <tr>
                        <td>Safes and Lockers with Locking Mechanism</td>
                        <td>
                          12%
                        </td>
                    </tr>
                    <tr>
                        <td>Lawn & Garden - Solar Panels</td>
                        <td>
                          6%
                        </td>
                    </tr>
                    <tr>
                        <td>Lawn & Garden - Leaf blower and Water pump</td>
                        <td>
                          6.50%
                        </td>
                    </tr>
                    <tr>
                        <td>Lawn & Garden - Commercial Agricultural Products</td>
                        <td>
                          3%
                        </td>
                    </tr>
                    <tr>
                        <td>Lawn & Garden- Chemical Pest Control, Mosquito nets, Bird control, Plant protection, Foggers</td>
                        <td>
                          7.0% for item price &lt;= 1,000<br/>
                          9.0% for item price &gt; 1,000
                        </td>
                    </tr>
                    <tr>
                        <td>Lawn & Garden - Solar Devices (Panels, Inverters, Charge controller, Battery, Lights, Solar gadgets)</td>
                        <td>
                          8%
                        </td>
                    </tr>
                    <tr>
                        <td>Lawn and Garden - Planters, Fertilisers, Watering and other subcategories</td>
                        <td>
                          13% for item price &lt;= 300<br/>
                          10% for item price &gt; 300 and &lt;= 15000<br/>
                          5% for item price &gt; 15000
                        </td>
                    </tr>
                    <tr>
                        <td>Lawn and Garden - Plants, Seeds & Bulbs</td>
                        <td>
                          9.5% for item price &lt;= INR 500<br/>
                          11% for item price &gt; INR 500
                        </td>
                    </tr>
                    <tr>
                        <td>Lawn & Garden - Outdoor equipments (Saws, Lawn Mowers, Cultivator, Tiller, String Trimmers, Water Pumps, Generators, Barbeque Grills, Greenhouses)</td>
                        <td>
                          6.50%
                        </td>
                    </tr>

                    
                    <tr>
                         <td rowspan="12" className="align-top">9. Kitchen, Large and Small Appliances</td> 
                        <td>Kitchen- Non Appliances</td>
                        <td>
                            <p>6% for item price &lt;= 300</p>
                            <p>11.5% for item price &gt; 300</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Kitchen - Glassware & Ceramicware</td>
                        <td>
                            <p>4.0% for item price &lt;= 500</p>
                            <p>10.0% for item price &gt; 500 and &lt;=1,000</p>
                            <p>11.5% for item price &gt;1,000</p>
                        </td> 
                    </tr>
                    <tr>
                        <td>Kitchen - Gas Stoves & Pressure Cookers</td>
                        <td>
                            <p>6% for item price &lt;= INR 1500</p>
                            <p>10% for item price &gt; INR 1500</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Cookware, Tableware & Dinnerware</td>
                        <td>
                            <p>5.0% for item price &lt;= 500</p>
                            <p>8.0% for item price &gt; 500 and &lt;= 1,000 </p>
                            <p>10.5% for item price &gt; 1,000</p>
                        </td>
                    </tr>
                    <tr> 
                        <td>Kitchen Tools & Supplies - Choppers, Knives, Bakeware & Accessories</td>
                        <td>
                            <p>2.0% for item price &lt;= 300</p>
                            <p>5.0% for item price &gt; 300 and &lt;= 500</p>
                            <p>10.5% for item price &gt; 500</p>
                        </td>
                    </tr>
                    <tr> 
                        <td>Kitchen & Bath fixtures, Cleaning Supplies, Hardware, Electricals and Building Materials</td>
                        <td>
                            <p>5.0% for item price &lt;= 300</p>
                            <p>12.0% for item price &gt; 300</p>
                        </td>
                    </tr>
                    <tr> 
                        <td>Large Appliances (excl. Accessories, Refrigerators and Chimneys)</td>
                        <td>5.50%</td>
                    </tr>
                    <tr> 
                        <td>Large Appliances – Accessories</td>
                        <td>16%</td>
                    </tr>
                    <tr> 
                        <td>Large Appliances – Chimneys</td>
                        <td>9.50%</td>
                    </tr>
                    <tr> 
                        <td>Large Appliances – Refrigerators</td>
                        <td>5%</td>
                    </tr>
                    <tr> 
                        <td>Small Appliances</td>
                        <td>
                            <p>6.5% for item price &lt;= 5000</p>
                            <p>8.0% for item price &gt; 5000</p>
                        </td>
                    </tr>
                    <tr> 
                        <td>Fans and Robotic Vacuums</td>
                        <td>
                            <p>6.5% for item price &lt;= 3000</p>
                            <p>8.0% for item price &gt; 3000</p>
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="6" className="align-top">10. Sports, Gym & Sporting Equipment</td>
                        <td>Bicycles</td>
                        <td>
                            <p>3.0% for item price &lt;= 500</p> 
                            <p>7.0% for item price &gt; 500 and &lt;=1,000</p>
                            <p>8.0% for item price &gt;1,000</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Gym Equipments</td>
                        <td>
                            <p>6.0% for item price &lt;= 300</p>
                            <p>8.0% for item price &gt; 300 and &lt;= 500</p>
                            <p>10.0% for item price &gt; 500 and &lt;= 1000</p>
                            <p>12.0% for item price &gt; 1,000</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Sports- Cricket and Badminton Equipments, Tennis, Table Tennis , Squash, Football, Volleyball, Basketball , Throwball, Swimming</td>
                        <td>
                            <p>5% for item price &lt;= INR 300</p>
                            <p>6% for item price &gt; INR 300 and &lt;= INR 500</p>
                            <p>8% for item price &gt; INR 500 and &lt;=INR 1000</p>
                            <p>8.5% for item price &gt; INR 1000</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Sports Collectibles</td>
                        <td>
                            <p>13% upto INR 300</p>
                            <p>17% more than INR 300</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Sports & Outdoors – Footwear</td>
                        <td>
                          10.5% for item price &lt;= 300<br/>
                          10.5% for item price &gt; 300 and &lt;= 500<br/>
                          12.5% for item price &gt;500 and &lt;=1,000<br/>
                          13.0% for item price &gt; 1,000

                        </td>
                    </tr>
                </tbody>
            </table> 
         </div>
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
            </div>
        </div>
    </section>
</>
  )
}

export default Categoryfeecal